export enum ButtonVariant {
  DEFAULT = "default",
  FILL_PRIMARY_CONTENT = "fillPrimaryContent",
  FILL_INFO = "fillInfo",
  OUTLINE = "outline",
}

export enum LinkButtonType {
  LINK = "link",
  DOWNLOAD_APP = "download_app",
  DOWNLOAD_FILE = "download_file",
  SCHEDULE_A_CALL = "schedule_a_call",
}
