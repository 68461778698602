import { TFunction } from "react-i18next";
import * as yup from "yup";
import { FIELDS_MAX_LENGTH } from "@app/constants/form-validation";

export const useValidationSchema = (t: TFunction<"translation", string>) =>
  yup.object({
    firstName: yup
      .string()
      .max(FIELDS_MAX_LENGTH.FIRST_NAME)
      .required(t("invalid-field")),
    lastName: yup
      .string()
      .max(FIELDS_MAX_LENGTH.LAST_NAME)
      .required(t("invalid-field")),
    email: yup.string().required(t("invalid-field")).email(t("invalid-email")),
    phoneCode: yup.object().shape({
      alphaCode: yup.string(),
      code: yup.string(),
    }),
    phone: yup
      .string()
      .matches(/^(?=.*\d)[\d\s]{2,32}$/, {
        message: t("invalid-phone"),
        excludeEmptyString: false,
      })
      .required(t("invalid-field")),
    howDidYouHear: yup
      .string()
      .max(FIELDS_MAX_LENGTH.HOW_DID_YOU_HEAR_ABOUT_US)
      .required(t("invalid-field")),
    isSwissResidentConfirmed: yup.boolean().required().oneOf([true]),
    isPrivacyPolicyConfirmed: yup.boolean().required().oneOf([true]),
  });
